import { Route, Switch } from "react-router-dom";
import HeaderNavigation from "./HeaderNavigation";
import Characters from "./Characters";
import Settings from "./Settings";

import "./styles.css";

export default function App() {
  const Home = () => <h1>Home</h1>;
  return (
    <div className="App">
      <HeaderNavigation />
      <Switch>
        <Route path="/characters">
          <Characters />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}
