import { NavLink, Switch, Route, useRouteMatch } from "react-router-dom";
//import styled from "styled-components";
import Character from "./Character";

export default function Characters() {
  let { url, path } = useRouteMatch();
  return (
    <div>
      <h1>Characters</h1>
      <nav>
        <NavLink to={url + "/mini"}>Mini</NavLink>
        <NavLink to={url + "/micky"}>Micky</NavLink>
        <NavLink to={url + "/donald"}>Donald</NavLink>
      </nav>

      <Switch>
        <Route exact path={path}>
          <h3>Select a character</h3>
        </Route>
        <Route path={path + "/:characterName"}>
          <Character />
        </Route>
      </Switch>
    </div>
  );
}
