import { NavLink } from "react-router-dom";

export default function HeaderNavigation() {
  return (
    <header className="header">
      <nav className="navigation">
        <NavLink exact to="/">
          Home
        </NavLink>
        <NavLink to="/characters">Characters</NavLink>
        <NavLink to="/settings">Settings</NavLink>
      </nav>
    </header>
  );
}
